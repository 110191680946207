/**
 * The mobile menu module.
 *
 * @return {void}
 */

export const mobileMenu = () => {
  const $body = document.querySelector("body");

  const toggleMenu = ($trigger) => {
    // Set the elements
    const menu = document.querySelector(".js-mobile-menu-flyout");
    const open = document.querySelector(".js-mobile-menu-open");
    const close = document.querySelector(".js-mobile-menu-close");

    $trigger.addEventListener("click", () => {
      $body.classList.toggle("overflow-hidden");
      menu.classList.toggle("opacity-0");
      menu.classList.toggle("pointer-events-none");
      open.classList.toggle("hidden");
      close.classList.toggle("hidden");
    });
  };

  const ownershipCloseMenu = ($trigger) => {
    const $headerOwnershipMobile = document.querySelector(
      ".header__ownership__mobile"
    );

    if ($headerOwnershipMobile) {
      const $headerOwnershipMobileBanners =
        $headerOwnershipMobile?.querySelectorAll(
          ".ownership__banner, .ownership__offers, .ownership__link, .ownership__faqs"
        );

      // Set the elements
      const menu = document.querySelector(".js-mobile-menu-flyout");
      const open = document.querySelector(".js-mobile-menu-open");
      const close = document.querySelector(".js-mobile-menu-close");

      $headerOwnershipMobileBanners.forEach(($headerOwnershipMobileBanner) => {
        $headerOwnershipMobileBanner.addEventListener("click", (event) => {
          $body.classList.remove("overflow-hidden");
          menu.classList.add("opacity-0");
          menu.classList.add("pointer-events-none");
          open.classList.remove("hidden");
          close.classList.add("hidden");
        });
      });
    }
  };

  const parentToggle = ($trigger) => {
    $trigger.addEventListener("click", () => {
      // Set the elements
      const $container = $trigger.closest(".js-mobile-menu-item-container");
      const $children = $container.querySelector(
        ".js-mobile-menu-child-container"
      );
      const parentTriggers = document.querySelectorAll(
        ".js-mobile-menu-parent"
      );

      parentTriggers.forEach((trigger) => {
        const container = trigger.closest(".js-mobile-menu-item-container");
        const children = container.querySelector(
          ".js-mobile-menu-child-container"
        );
        const chevron = trigger.querySelector(".js-mobile-menu-parent-chevron");

        children.classList.add("hidden");
        chevron.classList.remove("rotate-180");
        trigger.classList.remove("bg-sky-blue-75");
        trigger.classList.remove("-mx-4");
        trigger.classList.remove("px-4");
        trigger.classList.remove("py-2");
        trigger.classList.add("border-b");
        trigger.classList.add("border-black");
        trigger.classList.add("py-4");
      });

      $children.classList.remove("hidden");
      $trigger.classList.add("bg-sky-blue-75");
      $trigger.classList.add("-mx-4");
      $trigger.classList.add("px-4");
      $trigger.classList.add("py-2");
      $trigger.classList.remove("border-b");
      $trigger.classList.remove("border-black");
      $trigger.classList.remove("py-4");
    });
  };

  const menuTrigger = document.querySelector(".js-mobile-menu-trigger");
  const parentTriggers = document.querySelectorAll(".js-mobile-menu-parent");

  if (menuTrigger) {
    toggleMenu(menuTrigger);
    ownershipCloseMenu(menuTrigger);
  }

  if (parentTriggers) {
    parentTriggers.forEach((trigger) => {
      parentToggle(trigger);
    });
  }
};

// Export the module
export default mobileMenu;
