import Litepicker from "litepicker";

/**
 * Handle the contact.
 *
 * @return {void}
 */
export const contactFormHandler = () => {
  /**
   * Event handler to process the form submission.
   *
   * @param  {object}  event  The event object.
   * @return {void}
   */
  const submitFormEventHandler = (event) => {
    // Prevent the default action
    event.preventDefault();

    // Set the elements
    const $form = event.currentTarget;
    const $csrf = document.querySelector('meta[name="csrf"]');
    const $honeypot = $form.querySelector("input[name=hp]");
    const $newsletter = $form.querySelector("input[name=newsletter]");
    let contactSource,
      contactBody,
      firstName,
      lastName,
      email,
      name,
      address1,
      address2,
      town,
      postcode,
      where,
      number,
      dateOfTour;
    if ($form.id === "brochureDownload") {
      contactSource = "Download Brochure";
      firstName = $form.querySelector("input[name=first_name]");
      lastName = $form.querySelector("input[name=last_name]");
      email = $form.querySelector("input[name=email]");

      contactBody = JSON.stringify({
        csrf: $csrf.getAttribute("content"),
        honeypot: $honeypot.checked,
        first_name: firstName.value,
        last_name: lastName.value,
        email: email.value,
        source: contactSource,
        newsletter_signup: $newsletter.checked,
      });
    } else if ($form.id === "brochurePost") {
      contactSource = "Post Brochure";

      firstName = $form.querySelector("input[name=first_name]");
      lastName = $form.querySelector("input[name=last_name]");
      address1 = $form.querySelector("input[name=address_1]");
      address2 = $form.querySelector("input[name=address_2]");
      town = $form.querySelector("input[name=town]");
      postcode = $form.querySelector("input[name=postcode]");
      email = $form.querySelector("input[name=email]");

      contactBody = JSON.stringify({
        csrf: $csrf.getAttribute("content"),
        honeypot: $honeypot.checked,
        first_name: firstName.value,
        last_name: lastName.value,
        address_line_1: address1.value,
        address_line_2: address2.value,
        town: town.value,
        postcode: postcode.value,
        email: email.value,
        source: contactSource,
        newsletter_signup: $newsletter.checked,
      });
    } else if ($form.id === "salesPack") {
      contactSource = "Request Sales Pack";

      firstName = $form.querySelector("input[name=first_name]");
      lastName = $form.querySelector("input[name=last_name]");
      address1 = $form.querySelector("input[name=address_1]");
      address2 = $form.querySelector("input[name=address_2]");
      town = $form.querySelector("input[name=town]");
      postcode = $form.querySelector("input[name=postcode]");
      email = $form.querySelector("input[name=email]");
      where = $form.querySelector("input[name=where]");
      number = $form.querySelector("input[name=phone_number]");

      contactBody = JSON.stringify({
        csrf: $csrf.getAttribute("content"),
        honeypot: $honeypot.checked,
        first_name: firstName.value,
        last_name: lastName.value,
        address_line_1: address1.value,
        address_line_2: address2.value,
        town: town.value,
        postcode: postcode.value,
        email: email.value,
        where: where.value,
        phone_number: number.value,
        source: contactSource,
        newsletter_signup: $newsletter.checked,
      });
    } else if ($form.id === "parkTour") {
      contactSource = "Request Park Tour";

      firstName = $form.querySelector("input[name=first_name]");
      lastName = $form.querySelector("input[name=last_name]");
      address1 = $form.querySelector("input[name=address_1]");
      address2 = $form.querySelector("input[name=address_2]");
      town = $form.querySelector("input[name=town]");
      postcode = $form.querySelector("input[name=postcode]");
      email = $form.querySelector("input[name=email]");
      where = $form.querySelector("input[name=where]");
      number = $form.querySelector("input[name=phone_number]");
      dateOfTour = $form.querySelector("input[name=tour_date]");

      contactBody = JSON.stringify({
        csrf: $csrf.getAttribute("content"),
        honeypot: $honeypot.checked,
        first_name: firstName.value,
        last_name: lastName.value,
        email: email.value,
        where: where.value,
        phone_number: number.value,
        tour_date: dateOfTour.value,
        source: contactSource,
        newsletter_signup: $newsletter.checked,
      });
    }

    const $submit = $form.querySelector("button[type=submit]");

    // Set the data
    const submitText = $submit.textContent;

    // Disable the submit and change the text
    $submit.setAttribute("disabled", "disabled");
    $submit.classList.add("cursor-not-allowed");
    $submit.innerHTML = "Sending...";

    // Create a fetch post request
    fetch($form.getAttribute("action"), {
      method: "POST",
      body: contactBody,
    })
      .then((response) => response.json())
      .then((response) => {
        // Check a response error exists
        if (response.error) {
          // Display an alert
          alert(response.error_message);

          // Enable the submit and restore the original text
          $submit.removeAttribute("disabled");
          $submit.classList.remove("cursor-not-allowed");
          $submit.innerHTML = submitText;
        } else {
          // Redirect the user
          alert(response.success_message);
          $submit.innerHTML = submitText;

          const downloadLink = $form.dataset.pdfLink;
          if (downloadLink) {
            // const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = downloadLink;
            a.download = "brochure.pdf";
            // document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();
            a.remove();
          }
        }
      })
      .catch((response) => {
        // Display an alert
        alert("Something went wrong, please try again later.");

        // Enable the submit and restore the original text
        $submit.removeAttribute("disabled");
        $submit.classList.remove("cursor-not-allowed");
        $submit.innerHTML = submitText;
      });
  };

  // Set the form
  const $downloadBrochure = document.querySelector("#brochureDownload");
  const $postBrochure = document.querySelector("#brochurePost");
  const $requestSalesPack = document.querySelector("#salesPack");
  const $parkTour = document.querySelector("#parkTour");

  // Check if the form exists
  if ($downloadBrochure) {
    // Add a submit event handler to the form
    $downloadBrochure.addEventListener("submit", submitFormEventHandler);
  }

  // Check if the form exists
  if ($postBrochure) {
    // Add a submit event handler to the form
    $postBrochure.addEventListener("submit", submitFormEventHandler);
  }

  // Check if the form exists
  if ($requestSalesPack) {
    // Add a submit event handler to the form
    $requestSalesPack.addEventListener("submit", submitFormEventHandler);
  }

  // Check if the form exists
  if ($parkTour) {
    // Add a submit event handler to the form

    const $dateOfTourContainer = $parkTour.querySelector(".date-of-tour");

    const $dateOfTourValue = $dateOfTourContainer.querySelector(
      ".date-of-tour__value"
    );
    const $dateOfTourButton = $dateOfTourContainer.querySelector(
      ".date-of-tour__button"
    );
    const $dateOfTourSpan = $dateOfTourContainer.querySelector(
      ".date-of-tour__span"
    );
    const $dateOfTourSelector = $dateOfTourContainer.querySelector(
      ".date-of-tour__selector"
    );

    const date = new Date();
    // const maxDate = select.dataset.maximumDate;

    // Litepicker
    const picker = new Litepicker({
      element: $dateOfTourValue,
      inlineMode: true,
      autoRefresh: true,
      minDate: date,
      // maxDate: maximum,
      // lockDaysFilter: (day) => {
      //   const d = day.getDay();
      //   const m = day.getMonth();
      //   return [0, 6].includes(d) || [0, 1].includes(m);
      // },
      setup: (picker) => {
        picker.on("preselect", (date1, date2) => {
          $dateOfTourSpan.innerText = date1.dateInstance.toLocaleDateString();
          $dateOfTourSelector.classList.add("hidden");
        });
      },
    });

    $dateOfTourButton.addEventListener("click", (event) => {
      event.preventDefault();
      $dateOfTourSelector.classList.toggle("hidden");
    });

    $parkTour.addEventListener("submit", submitFormEventHandler);
  }
};

// Export the login form handler http://eepurl.com/iiQeqH
export default contactFormHandler;
