/**
 * Functions for the search button.
 *
 * @param  {object}  event  The event object.
 * @return {void}
 */
export const searchFunction = ($trigger) => {
  // Enable/disable search button
  const checkboxes = document.querySelectorAll(".js-booking-banner-checkbox");
  let checked = 0;

  checkboxes.forEach((checkbox) => {
    checkbox.addEventListener("change", () => {
      checkboxes.forEach((box) => {
        if (box.checked) {
          checked++;
        }
      });

      if (checked === 3) {
        $trigger.classList.remove("pointer-events-none");
        $trigger.classList.remove("opacity-50");
      } else {
        $trigger.classList.add("pointer-events-none");
        $trigger.classList.add("opacity-50");
      }
    });
  });

  let select;

  if (window.innerWidth < 1024) {
    select = document.querySelector(".js-booking-banner-date-select-mobile");
  } else {
    select = document.querySelector(".js-booking-banner-date-select");
  }

  let accommodationSelect;

  if (window.innerWidth < 1024) {
    accommodationSelect = document.querySelector(
      ".js-booking-banner-accommodation-select-mobile"
    );
  } else {
    accommodationSelect = document.querySelector(
      ".js-booking-banner-accommodation-select"
    );
  }

  // Search button click event
  $trigger.addEventListener("click", () => {
    // Set the elements
    let accommodation;
    let adults = 0;
    let children = 0;
    let infants = 0;
    let dogs = 0;

    // Get selected accommodation data
    const accommodationOptions = accommodationSelect.querySelectorAll(
      ".js-booking-banner-accommodation-type"
    );
    accommodationOptions.forEach((element) => {
      if (element.classList.contains("active-accommodation-option")) {
        if (
          element.dataset.type == "lodges" ||
          element.dataset.type == "holiday-homes"
        ) {
          accommodation = 1;
        } else if (element.dataset.type == "touring") {
          accommodation = 2;
        } else if (element.dataset.type == "glamping") {
          accommodation = 3;
        } else if (element.dataset.type == "camping") {
          accommodation = 4;
        } else if (element.dataset.type == "cottage") {
          accommodation = 8;
        }
      }
    });

    // Get selected guest data
    const guestOptions = document.querySelectorAll(
      ".js-booking-banner-guests-type"
    );

    guestOptions.forEach((element) => {
      const qty = element.querySelector(".js-booking-banner-guests-quantity");
      const q = parseInt(qty.innerText);

      if (q > 0) {
        if (element.dataset.type === "adults") {
          adults = q;
        } else if (element.dataset.type === "children") {
          children = q;
        } else if (element.dataset.type === "infants") {
          infants = q;
        } else if (element.dataset.type === "dogs") {
          dogs = q;
        }
      }
    });

    // Get selected duration data
    let nights, nightsContainer;
    const inlineContainer = document.querySelector(
      ".js-inline-booking-date-container"
    );

    // If accommodation type is camping or touring
    if (accommodation == 2 || accommodation == 4) {
      if (window.innerWidth > 1024) {
        nightsContainer = document.querySelector(".js-booking-banner-nights");
      } else {
        nightsContainer = document.querySelector(
          ".js-booking-banner-nights-mobile"
        );
      }

      nights = nightsContainer.querySelector(
        ".js-booking-banner-nights-count"
      ).innerText;
    } else {
      let durations;

      if (window.innerWidth > 1024) {
        durations = document.querySelector(".js-booking-banner-durations");
      } else {
        durations = document.querySelector(
          ".js-booking-banner-durations-mobile"
        );
      }

      const duration = durations.querySelector(".active-duration");
      nights = duration.dataset.duration;
    }

    // Get selected date data
    let calendar;

    if (window.innerWidth < 1024) {
      calendar = document.querySelector(".js-booking-banner-calendar-mobile");
    } else {
      calendar = document.querySelector(".js-booking-banner-calendar");
    }

    const date = calendar.value;

    // Get any accommodation ID's & set URL
    const bookingIDs = document.querySelectorAll(".js-booking-banner-id");
    let accommodationIdString, path;

    const clientId = sessionStorage.getItem("ga-c-id");
    const sessionId = sessionStorage.getItem("ga-s-id");
    let clientString = false;
    let sessionString = false;

    if (bookingIDs[0]) {
      bookingIDs.forEach((element, index) => {
        const id = element.dataset.id;

        if (index === 0) {
          accommodationIdString = `${id}`;
        } else {
          accommodationIdString = accommodationIdString + `+${id}`;
        }
      });

      path = `https://bookings.ladrambay.co.uk/AvailabilityRange.aspx?resparkid=3990&resholidaytype=${accommodation}&resadults=${adults}&reschildren=${children}&resinfants=${infants}&resnumnights=${nights}&resarrivedate=${date}&resdogs=${dogs}&resroomtypeid=${accommodationIdString}`;
    } else {
      path = `https://bookings.ladrambay.co.uk/AvailabilityRange.aspx?resparkid=3990&resholidaytype=${accommodation}&resadults=${adults}&reschildren=${children}&resinfants=${infants}&resnumnights=${nights}&resarrivedate=${date}&resdogs=${dogs}`;
    }

    // Go to booking system

    if (clientId) {
      clientString = `&clientId=${clientId}`;
    }

    if (sessionId) {
      sessionString = `&sessionId=${sessionId}`;
    }

    if (clientId && sessionId) {
      const ids = clientString.concat(sessionString);
      path = path.concat(ids);
    } else if (clientId) {
      path = path.concat(clientString);
    }

    window.location.href = path;
  });
};

// Export the module
export default searchFunction;
