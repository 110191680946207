import Litepicker from "litepicker";
import checkBoxes from "./checkboxes";

/**
 * Functions for the date selector.
 *
 * @param  {object}  event  The event object.
 * @return {void}
 */
export const inlineDate = (container) => {
  // Set the elements
  const durations = container.querySelectorAll(".js-booking-banner-duration");
  const calendar = document.querySelector(".js-booking-banner-calendar");
  const dateCheckbox = document.getElementById("date-checkbox");
  const reset = document.querySelector(".js-inline-booking-reset");
  const label = document.querySelector(".js-inline-booking-date-label");
  const guestsLabel = document.querySelector(".js-inline-booking-guests-text");
  const quantities = document.querySelectorAll(
    ".js-booking-banner-guests-quantity"
  );
  const guestCheckbox = document.getElementById("guests-checkbox");
  const nightsIncrease = document.querySelector(
    ".js-booking-banner-nights-increase"
  );
  const nightsDecrease = document.querySelector(
    ".js-booking-banner-nights-decrease"
  );
  const nightsCount = document.querySelector(".js-booking-banner-nights-count");
  const anyDay = document.querySelector(".js-any-date");

  const date = new Date();
  const maxDate = calendar.dataset.maximumDate;
  const dateString = "20" + maxDate + " GMT";
  const maximum = Date.parse(dateString);
  let picker;

  // Litepicker
  if (anyDay !== null) {
    picker = new Litepicker({
      element: calendar,
      inlineMode: true,
      autoRefresh: true,
      minDate: date,
      maxDate: maximum,
      lockDaysFilter: (day) => {
        const m = day.getMonth();

        return [0, 1, 10, 11].includes(m);
      },
    });
  } else {
    picker = new Litepicker({
      element: calendar,
      inlineMode: true,
      autoRefresh: true,
      minDate: date,
      maxDate: maximum,
      lockDaysFilter: (day) => {
        const d = day.getDay();
        const m = day.getMonth();
        return [0, 1, 2, 3, 4, 6].includes(d) || [0, 1, 10, 11].includes(m);
      },
    });
  }

  // Calendar options for 3 nights
  const twoNights = {
    element: calendar,
    inlineMode: true,
    autoRefresh: true,
    minDate: date,
    maxDate: maximum,
    lockDaysFilter: (day) => {
      const d = day.getDay();
      const m = day.getMonth();
      return [0, 1, 2, 3, 4, 6].includes(d) || [0, 1, 10, 11].includes(m);
    },
  };

  // Calendar options for 3 nights
  const threeNights = {
    element: calendar,
    inlineMode: true,
    autoRefresh: true,
    minDate: date,
    maxDate: maximum,
    lockDaysFilter: (day) => {
      const d = day.getDay();
      const m = day.getMonth();
      return [0, 1, 2, 3, 4, 6].includes(d) || [0, 1, 10, 11].includes(m);
    },
  };

  // Calendar options for 4 nights
  const fourNights = {
    element: calendar,
    inlineMode: true,
    autoRefresh: true,
    minDate: date,
    maxDate: maximum,
    lockDaysFilter: (day) => {
      const d = day.getDay();
      const m = day.getMonth();
      return [0, 2, 3, 4, 5, 6].includes(d) || [0, 1, 10, 11].includes(m);
    },
  };

  // Calendar options for 7 nights
  const weeks = {
    element: calendar,
    inlineMode: true,
    autoRefresh: true,
    minDate: date,
    maxDate: maximum,
    lockDaysFilter: (day) => {
      const d = day.getDay();
      const m = day.getMonth();
      return [0, 2, 3, 4, 6].includes(d) || [0, 1, 10, 11].includes(m);
    },
  };

  // Calendar options for 7 nights
  const weeksWithSaturday = {
    element: calendar,
    inlineMode: true,
    autoRefresh: true,
    minDate: date,
    maxDate: maximum,
    lockDaysFilter: (day) => {
      const d = day.getDay();
      const m = day.getMonth();
      return [0, 2, 3, 4].includes(d) || [0, 1, 10, 11].includes(m);
    },
  };

  // Duration selection
  durations.forEach((element) => {
    element.addEventListener("click", () => {
      // change inactive button styling

      durations.forEach((currentElement) => {
        currentElement.classList.add("text-deep-grey");
        currentElement.classList.remove("bg-ocean-blue");
        currentElement.classList.remove("border-ocean-blue");
        currentElement.classList.remove("text-white");
        currentElement.classList.add("border-black");
        currentElement.classList.remove("active-duration");
      });

      // change active button styling
      element.classList.remove("text-deep-grey");
      element.classList.add("bg-ocean-blue");
      element.classList.add("border-ocean-blue");
      element.classList.add("text-white");
      element.classList.remove("border-black");
      element.classList.add("active-duration");

      if (element.dataset.duration === "2") {
        picker.clearSelection();
        picker.setOptions(twoNights);
        const source = element.dataset.duration;
        updateLabel(source);
      } else if (element.dataset.duration === "3") {
        picker.clearSelection();
        picker.setOptions(threeNights);
        const source = element.dataset.duration;
        updateLabel(source);
      } else if (element.dataset.duration === "4") {
        picker.clearSelection();
        picker.setOptions(fourNights);
        const source = element.dataset.duration;
        updateLabel(source);
      } else if (element.dataset.duration === "7") {
        let includeSaturday = false;
        if (
          element.dataset.type == "lodges" ||
          element.dataset.type == "holiday-homes" ||
          element.dataset.type == "glamping"
        ) {
          includeSaturday = true;
        }

        if (includeSaturday == true) {
          picker.setOptions(weeksWithSaturday);
        } else {
          picker.setOptions(weeks);
        }
        picker.clearSelection();

        const source = element.dataset.duration;
        updateLabel(source);
      } else if (element.dataset.duration === "14") {
        let includeSaturday = false;
        if (
          element.dataset.type == "lodges" ||
          element.dataset.type == "holiday-homes" ||
          element.dataset.type == "glamping"
        ) {
          includeSaturday = true;
        }

        if (includeSaturday == true) {
          picker.setOptions(weeksWithSaturday);
        } else {
          picker.setOptions(weeks);
        }
        picker.clearSelection();

        const source = element.dataset.duration;
        updateLabel(source);
      }

      dateCheckbox.checked = false;
      checkBoxes();

      const source = element.dataset.duration;
      updateLabel(source);
    });
  });

  picker.on("before:click", (target) => {
    if (
      target.classList.contains("day-item") &&
      target.classList.contains("is-locked") !== true
    ) {
      picker.setDateRange();
      dateCheckbox.checked = true;
      checkBoxes();
      const source = "date";
      delay(100).then(() => updateLabel(source));

      // Enable reset button
      if (reset) {
        reset.classList.remove("opacity-50");
        reset.classList.remove("pointer-events-none");
      }
    }
  });

  function delay(time) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  if (nightsIncrease) {
    nightsIncrease.addEventListener("click", () => {
      const currentCount = parseInt(nightsCount.innerText);
      const date = calendar.value;

      if (currentCount < 14) {
        const newCount = currentCount + 1;
        nightsCount.innerText = newCount;

        if (label.innerHTML.includes("from")) {
          label.innerHTML = `${newCount} nights from ${date}`;
        } else {
          label.innerHTML = `${newCount} nights`;
        }
      }
    });
  }

  if (nightsDecrease) {
    nightsDecrease.addEventListener("click", () => {
      const currentCount = parseInt(nightsCount.innerText);
      const date = calendar.value;

      if (currentCount > 1) {
        const newCount = currentCount - 1;
        nightsCount.innerText = newCount;

        if (label.innerHTML.includes("from")) {
          if (newCount == 1) {
            label.innerHTML = `${newCount} night from ${date}`;
          } else {
            label.innerHTML = `${newCount} nights from ${date}`;
          }
        } else {
          if (newCount == 1) {
            label.innerHTML = `${newCount} night`;
          } else {
            label.innerHTML = `${newCount} nights`;
          }
        }
      }
    });
  }

  // Update the label on date change
  const updateLabel = (source) => {
    const duration = document.querySelector(".active-duration");
    let nights;

    if (anyDay !== null) {
      nights = document.querySelector(
        ".js-booking-banner-nights-count"
      ).innerText;
    } else {
      nights = duration.dataset.duration;
    }

    const date = calendar.value;

    if (source == "date") {
      if (date != null) {
        label.innerHTML = `${nights} nights from ${date}`;
      } else {
        label.innerHTML = `Select Duration and dates`;
      }
    } else if (
      source == "3" ||
      source == "4" ||
      source == "7" ||
      source == "14"
    ) {
      label.innerHTML = `${source} nights`;
    }
  };

  // Reset functionality
  reset.addEventListener("click", () => {
    // Reset date label
    label.innerText = "3 nights";

    // Reset guest label
    guestsLabel.innerText = `Add Guests`;

    // Reset quantities
    quantities.forEach((element) => {
      element.innerText = 0;
    });

    // Reset duration
    durations.forEach((currentElement) => {
      currentElement.classList.add("text-deep-grey");
      currentElement.classList.remove("bg-ocean-blue");
      currentElement.classList.remove("border-ocean-blue");
      currentElement.classList.remove("text-white");
      currentElement.classList.add("border-black");
      currentElement.classList.remove("active-duration");

      if (currentElement.dataset.duration === "3") {
        // change active button styling
        currentElement.classList.remove("text-deep-grey");
        currentElement.classList.add("bg-ocean-blue");
        currentElement.classList.add("border-ocean-blue");
        currentElement.classList.add("text-white");
        currentElement.classList.remove("border-black");
        currentElement.classList.add("active-duration");
      }
    });

    // Reset calendar
    picker.clearSelection();
    picker.setOptions(threeNights);
    picker.gotoDate(date);

    // Reset checkboxes
    guestCheckbox.checked = false;
    dateCheckbox.checked = false;
  });
};

// Export the module
export default inlineDate;
