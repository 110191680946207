import checkBoxes from "./checkboxes";

/**
 * Functions for the accommodation selector.
 *
 * @param  {object}  event  The event object.
 * @return {void}
 */
export const accommodationSelector = ($trigger) => {
  // Set the elements
  let select,
    dateSelect,
    dateTrigger,
    guestsSelect,
    guestsTrigger,
    guestsChevron,
    bookingReset,
    durations,
    nights;
  if (window.innerWidth < 1024) {
    select = document.querySelector(
      ".js-booking-banner-accommodation-select-mobile"
    );
    bookingReset = document.querySelector(".js-booking-banner-reset-mobile");

    // Date dropdown
    dateSelect = document.querySelector(
      ".js-booking-banner-date-select-mobile"
    );
    dateTrigger = document.querySelector(
      ".js-booking-banner-date-trigger-mobile"
    );
    durations = document.querySelector(".js-booking-banner-durations-mobile");
    nights = document.querySelector(".js-booking-banner-nights-mobile");

    // Guest dropdown
    guestsSelect = document.querySelector(
      ".js-booking-banner-guests-select-mobile"
    );
    guestsTrigger = document.querySelector(
      ".js-booking-banner-guests-trigger-mobile"
    );
    guestsChevron = guestsTrigger.querySelector(".js-booking-banner-chevron");
  } else {
    select = document.querySelector(".js-booking-banner-accommodation-select");
    bookingReset = document.querySelector(".js-booking-banner-reset");

    // Date dropdown
    dateSelect = document.querySelector(".js-booking-banner-date-select");
    dateTrigger = document.querySelector(".js-booking-banner-date-trigger");
    durations = document.querySelector(".js-booking-banner-durations");
    nights = document.querySelector(".js-booking-banner-nights");

    // Guest dropdown
    guestsSelect = document.querySelector(".js-booking-banner-guests-select");
    guestsTrigger = document.querySelector(".js-booking-banner-guests-trigger");
    guestsChevron = guestsTrigger.querySelector(".js-booking-banner-chevron");
  }

  const chevron = $trigger.querySelector(".js-booking-banner-chevron");
  const options = select.querySelectorAll(
    ".js-booking-banner-accommodation-type"
  );
  const label = $trigger.querySelector(".js-booking-banner-accommodation-text");
  const checkbox = document.getElementById("accommodation-checkbox");
  const background = document.querySelector(".js-booking-banner-background");

  $trigger.addEventListener("click", () => {
    if ($trigger.classList.contains("select-active")) {
      $trigger.classList.remove("select-active");
      select.classList.add("hidden");
      chevron.classList.add("rotate-180");
      select.classList.remove("z-modal");
      if (background) {
        background.classList.add("hidden");
      }
    } else {
      $trigger.classList.add("select-active");
      select.classList.remove("hidden");
      chevron.classList.remove("rotate-180");
      select.classList.add("z-modal");
      if (background) {
        background.classList.remove("hidden");
      }

      // Close date dropdown
      dateTrigger.classList.remove("select-active");
      dateSelect.classList.add("hidden");
      dateSelect.classList.remove("z-modal");

      // Close guests dropdown
      guestsTrigger.classList.remove("select-active");
      guestsSelect.classList.add("hidden");
      guestsSelect.classList.remove("z-modal");
      guestsChevron.classList.add("rotate-180");
    }
  });

  if (background) {
    background.addEventListener("click", () => {
      $trigger.classList.remove("select-active");
      $trigger.classList.add("bg-white");
      $trigger.classList.remove("bg-sky-blue");
      select.classList.add("hidden");
      chevron.classList.remove("rotate-180");
      select.classList.remove("z-modal");
      background.classList.add("hidden");
    });
  }

  options.forEach((option) => {
    option.addEventListener("click", () => {
      const empty = option.querySelector(".js-booking-banner-option-empty");
      const checked = option.querySelector(
        ".js-booking-banner-option-selected"
      );
      $trigger.classList.remove("bg-white");
      $trigger.classList.add("bg-sky-blue");

      // update durations
      if (
        option.dataset.type == "camping" ||
        option.dataset.type == "touring"
      ) {
        nights.classList.remove("hidden");
        durations.classList.add("hidden");
      } else {
        nights.classList.add("hidden");
        durations.classList.remove("hidden");
      }

      // deactivate options
      options.forEach((currentOption) => {
        const currentEmpty = currentOption.querySelector(
          ".js-booking-banner-option-empty"
        );
        const currentChecked = currentOption.querySelector(
          ".js-booking-banner-option-selected"
        );

        currentOption.classList.remove("active-accommodation-option");
        currentEmpty.classList.remove("hidden");
        currentChecked.classList.add("hidden");
      });

      // activate clicked option
      option.classList.add("active-accommodation-option");
      empty.classList.toggle("hidden");
      checked.classList.toggle("hidden");
      checkbox.checked = true;
      checkBoxes();

      // Update text
      label.innerText = option.dataset.label;

      // Enable reset button
      if (bookingReset) {
        bookingReset.classList.remove("opacity-50");
        bookingReset.classList.remove("pointer-events-none");
      }
    });
  });
};

// Export the module
export default accommodationSelector;
