import { Swiper, Scrollbar, Pagination, Navigation } from "swiper";

Swiper.use([Scrollbar]);
Swiper.use([Pagination]);
Swiper.use([Navigation]);

/**
 * The facility popup module.
 *
 * @return {void}
 */
export const facilityPopup = () => {
  const $triggers = document.querySelectorAll(".js-facility-popup-trigger");
  const $container = document.querySelector(".js-facility-popup-container");
  const $closeTriggers = document.querySelectorAll(".js-facility-popup-close");
  const $popups = $container.querySelectorAll(".js-facility-popup");
  const $zoomTriggers = $container.querySelectorAll(".js-facility-popup-zoom");
  const body = document.getElementById("body");
  let $menuModalSwiper;

  // Reveal popups
  $triggers.forEach(($trigger) => {
    $trigger.addEventListener("click", () => {
      $container.classList.remove("hidden");
      body.classList.add("h-full");
      body.classList.add("overflow-hidden");

      $popups.forEach(($popup) => {
        $popup.classList.add("hidden");

        if ($popup.dataset.title === $trigger.dataset.title) {
          $popup.classList.remove("hidden");
          $menuModalSwiper = $popup.querySelector(".js-menu-modal-swiper");
        }
      });

      if ($menuModalSwiper) {
        const menuswiper = new Swiper($menuModalSwiper, {
          slidesPerView: 1,
          spaceBetween: 32,
          centeredSlides: true,
          navigation: {
            nextEl: ".menu-modal-swiper-button-next-mobile",
            prevEl: ".menu-modal-swiper-button-prev-mobile",
          },
          // pagination: {
          //   el: ".menu-modal-swiper-pagination",
          //   clickable: true,
          //   type: 'bullets',
          //   bulletElement: "div",
          //   bulletClass: "inline-review-swiper-pagination-bullet",
          //   bulletActiveClass: "inline-review-swiper-pagination-bullet-active"
          // },
          breakpoints: {
            // when window width is >= 320px
            1023: {
              navigation: {
                nextEl: ".menu-modal-swiper-button-next",
                prevEl: ".menu-modal-swiper-button-prev",
              },
            },
          },
        });

        $zoomTriggers.forEach((trigger) => {
          trigger.addEventListener("click", () => {
            const zoomOpen = trigger.querySelector(".zoom-open");
            const zoomClose = trigger.querySelector(".zoom-close");
            const zoomTarget = $menuModalSwiper.querySelector(
              ".swiper-slide-active"
            );
            const zoomImage = zoomTarget.querySelector(".menu-swiper-image");

            zoomTarget.classList.toggle("overflow-y-auto");
            zoomImage.classList.toggle("scale-150");
            zoomOpen.classList.toggle("hidden");
            zoomClose.classList.toggle("hidden");
          });
        });
      }
    });
  });

  // Hide popups on background click
  // $container.addEventListener("click", () => {
  //   $container.classList.add("opacity-0");
  //   $container.classList.add("pointer-events-none");

  //   $popups.forEach(popup => {
  //     popup.classList.add("opacity-0");
  //     popup.classList.add("pointer-events-none");
  //   });
  // });

  // Hide popups on close button click
  $closeTriggers.forEach(($trigger) => {
    $trigger.addEventListener("click", () => {
      $container.classList.add("hidden");
      body.classList.remove("h-full");
      body.classList.remove("overflow-hidden");

      $popups.forEach((popup) => {
        popup.classList.add("hidden");
      });
    });
  });
};

// Export the module
export default facilityPopup;
