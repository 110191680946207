import checkBoxes from "./checkboxes";

/**
 * Functions for the guests selector.
 *
 * @param  {object}  event  The event object.
 * @return {void}
 */
export const inlineGuests = (container) => {
  // Set the elements
  const increaseTriggers = container.querySelectorAll(
    ".js-inline-booking-guests-increase"
  );
  const decreaseTriggers = container.querySelectorAll(
    ".js-inline-booking-guests-decrease"
  );
  const bookingReset = document.querySelector(".js-inline-booking-reset");
  const label = document.querySelector(".js-inline-booking-guests-text");
  const checkbox = document.getElementById("guests-checkbox");

  // Update the label on quantity change
  const updateTotal = () => {
    const quantities = document.querySelectorAll(
      ".js-booking-banner-guests-quantity"
    );
    let quantity = 0;

    quantities.forEach((element) => {
      const value = parseInt(element.innerText);
      quantity = quantity + value;
    });

    if (quantity === 1) {
      label.innerText = `${quantity} Guest`;
    } else if (quantity === 0) {
      label.innerText = `Add Guests`;
    } else {
      label.innerText = `${quantity} Guests`;
    }
  };

  // Decrease quantity triggers
  decreaseTriggers.forEach((trigger) => {
    trigger.addEventListener("click", () => {
      const container = trigger.closest(".js-booking-banner-guests-type");
      const quantity = container.querySelector(
        ".js-booking-banner-guests-quantity"
      );
      const amount = parseInt(quantity.innerText);

      if (amount > 0) {
        quantity.innerText = amount - 1;
        updateTotal();
      }

      if (container.dataset.type == "adults" && amount == 1) {
        checkbox.checked = false;
        checkBoxes();
      }
    });
  });

  // Increase quantity triggers
  increaseTriggers.forEach((trigger) => {
    trigger.addEventListener("click", () => {
      const container = trigger.closest(".js-booking-banner-guests-type");
      const quantity = container.querySelector(
        ".js-booking-banner-guests-quantity"
      );
      const amount = parseInt(quantity.innerText);

      if ((container.dataset.type === "dogs" && amount === 3) === false) {
        quantity.innerText = amount + 1;
        updateTotal();
      }

      if (container.dataset.type == "adults") {
        checkbox.checked = true;
        checkBoxes();
      }

      // Enable reset button
      if (bookingReset) {
        bookingReset.classList.remove("opacity-50");
        bookingReset.classList.remove("pointer-events-none");
      }
    });
  });
};

// Export the module
export default inlineGuests;
